import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';

@Injectable({providedIn: 'root'})
export class ScreenshotResults implements ICommunicationEvent {
	constructor() {}

	get eventName(): string {
		return PlatformCommunicationEvents.ScreenshotResults;
	}

    handleEvent(event: any): void {
        
    }
}