import { Injectable } from '@angular/core';
import { ICommunicationEvent } from '../ICommunicationEvent';
import { PlatformCommunicationEvents } from '@shared/generalInterfaces';
import { StickyHeaderService } from '../../../services/stickyHeaderService/stickyHeader.service';

@Injectable({providedIn: 'root'})
export class ScreenshotEnabled implements ICommunicationEvent {
	constructor(private stickyHeaderService: StickyHeaderService) {}

	get eventName(): string {
		return PlatformCommunicationEvents.ScreenshotEnabled;
	}

    handleEvent(event: any): void {
        this.stickyHeaderService.screenshotsEnabled$.next(true);
    }
}