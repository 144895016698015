import { IShellEvent } from './IShellEvent';
import { ShellCommunicationEvents } from '@shared/generalInterfaces';
import { Injectable } from '@angular/core';
import { ApplicationNavigationService } from 'app/services/shell/application-navigation/application-navigation.service';

@Injectable({providedIn: 'root'})
export class CloseMeEvent implements IShellEvent {

	constructor(private applicationNavigationService: ApplicationNavigationService) { }

	get eventName(): string {
		return ShellCommunicationEvents.CloseMeEvent;
	}

	async handleCustomEvent(event: any) {
        this.applicationNavigationService.navigateBack(event.eventHeader.publisher);
	}

	handlePostMessage(event): void {}
}
