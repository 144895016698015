import { Injectable } from '@angular/core';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { EupRoutesService } from '@core/eupRoutes.service';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { AppConfigService } from '../appConfig/appConfigService';
import { TranslateService } from '@ngx-translate/core';
import { catchError, filter, switchMap, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { IVisitReportInputs, IVisitReportSdk } from '../../microfrontend-interfaces/IVisitReport';
import { TimberService } from '@logging/timber.service';
import { SessionInfo } from '../authentication/auth.service';
import { ShellContextService } from '../shell-context/shell-context.service';

@Injectable({
	providedIn: 'root',
})
export class VisitReportService {

	private compName = 'VisitReportService';

	private authContext$: Observable<SessionInfo> = this.shellContextService.getContext().pipe(
		filter(context => !!context.session.sessionId && !!context.security.accessToken),
		map(context => {
			return {
				sessionId: context.session.sessionId,
				accessToken: context.security.accessToken,
				sessionType: context.session.sessionType
			};
		})
	);

	private get assetsMapEndpoint() {
		return `${this.visitReportEndpoint}/assets/assets.map.json`;
	}

	private get visitReportEndpoint() {
		return this.appConfigService.appSettings.visitReportEndpoint;
	}

	constructor(
		private globalSettings: GlobalSettingsService,
		public eupRoutesService: EupRoutesService,
		private appConfigService: AppConfigService,
		private translateService: TranslateService,
		private logger: TimberService,
		private shellContextService: ShellContextService
	) {}

	private sdk$: Observable<IVisitReportSdk>;

	static loadJavaScript(containerToAttach: HTMLElement, src: string): Promise<void> {
		const scriptElement: HTMLScriptElement = document.createElement('script');
		scriptElement.type = 'text/javascript';
		scriptElement.src = src;
		const promise = new Promise<void>((resolve, reject) => {
			const hadleLoadSuccess = () => {
				scriptElement.removeEventListener('load', hadleLoadSuccess);
				resolve();
			};

			const handleLoadError = (e: ErrorEvent) => {
				scriptElement.removeEventListener('error', handleLoadError);
				reject(e);
			};

			scriptElement.addEventListener('load', hadleLoadSuccess);
			scriptElement.addEventListener('error', handleLoadError);
		});
		containerToAttach.appendChild(scriptElement);

		return Promise.race([promise, this.promiseTimeout()]) ;
	}

	static promiseTimeout(timeoutMs: number = 30_000): Promise<void> {
		return new Promise<void>((_, reject) => setTimeout(() => reject(new Error(`Visit report sdk loading failed due to timeout ${timeoutMs}`)), timeoutMs));
	}

	isNewVisitReportAvailable(): boolean {
		return !!this.visitReportEndpoint;
	}

	loadSdk(container: HTMLElement): Observable<boolean> {
		if (!this.sdk$) {
			this.sdk$ = from(this.loadVisitReportScript(container)).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
		}

		return this.sdk$.pipe(map((sdk) => { return !!sdk; }));
	}

	async loadVisitReportScript(container: HTMLElement): Promise<IVisitReportSdk> {
		let error: Error;

		try {
			const response = await fetch(this.assetsMapEndpoint);
			const { main } = await response.json() as { main: string };

			try {
				await VisitReportService.loadJavaScript(container, `${this.visitReportEndpoint}/${main}`);
				return window.visitReportSdk;
			} catch (e) {
				this.logError(e, `Main Script loading for Visit Report is failed with error`);
				error = e;
			}
		} catch (e) {
			this.logError(e, `Assets Map loading for Visit Report is failed with error`);
			error = e;
		}

		if (error) {
			throw error;
		}
	}

	createMicrofrontendVisitReport(orderId: number, parentEl: HTMLElement, tagName: string): Observable<HTMLElement> {
		return this.sdk$.pipe(
			withLatestFrom(this.authContext$),
			map(([sdk, authContext]) => {
				try {
					const inputs: IVisitReportInputs = {
						orderId,
						parentEl,
						componentTagName: tagName,
						isMIDC: true,
						authInfo: {
							...authContext,
							authUrl: this.eupRoutesService.iTeroWebAuthApiUrl
						},
						...this.getInputParameters()
					};
					this.logger.info('Create visit report as standalone app', { module: this.compName });
					return sdk.bootstrapApp(inputs);
				} catch (e) {
					this.logger.error(`Web Component creation of Visit Report is failed with error: ${e.message}`, { module: this.compName });
					throw e;
				}
			})
		);
	}

	createVisitReportFromPackage(orderId: number, parentEl: HTMLElement, tagName: string): Observable<HTMLElement> {
		return from(import('@itero/visit-report-client/main-es5')).pipe(
			map(() => {
				const webComponent = document.createElement(tagName);
				const setting = this.getInputParameters();
				const reportInfo = {
					orderId: orderId,
					doctorId: setting.doctorId,
					companyId: setting.companyId,
					serverUrl: setting.serverUrl
				};
				webComponent.setAttribute('report-info', JSON.stringify(reportInfo));
				webComponent.setAttribute('language-code', setting.languageCode);
				webComponent.setAttribute('logger-url', setting.loggerUrl);

				parentEl.appendChild(webComponent);
				this.logger.info('Create visit report as npm package', { module: this.compName });

				return webComponent;
			}),
			catchError((e: Error) => {
				this.logger.error(`Loading NPM package of Visit Report is failed with error: ${e.message}`, { module: this.compName });

				throw e;
			})
		);
	}

	getInputParameters() {
		const settings = this.globalSettings.get();
		return {
			doctorId: settings.selectedDoctorId.toString(),
			companyId: settings.selectedCompanyId.toString(),
			languageCode: this.translateService.currentLang,
			serverUrl: this.eupRoutesService.serverUrl,
			loggerUrl: this.appConfigService.appSettings.loggingEndpoint,
			staticFilesEndpoint: this.appConfigService.appSettings.visitReportEndpoint,
		};
	}

	private logError(e: Error, message: string) {
		for (const [key, value] of Object.entries(e)) {
			if (!!value && value.length < 500) {
				this.logger.error(`${message}: ${key}: ${value}`, { module: this.compName });
			}
		}
	}
}
