import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../services/appConfig/appConfigService';
import { navigationErrorPrefix } from 'assets/consts';
import { IMyIteroException } from '@shared/generalInterfaces';
import { TimberService } from '@logging/timber.service';
import { EupToastService } from '@core/toastr/eupToast.service';
import { LogParameters } from '@logging/interfaces/log.parameters';
import {SplunkRum} from '@splunk/otel-web';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
	constructor(private injector: Injector, private translateService: TranslateService, private appConfigService: AppConfigService) {}

	handleError(error: any) {
		if(SplunkRum.inited) {
			SplunkRum.error(error);
		}
		const timberMsg = `${error.message ? error.message : error}`;
		const logParameters: LogParameters = { module: 'ErrorHandler' };

		if (error?.headers?.get('X-Trace')) {
			logParameters.traceId = error.headers.get('X-Trace');
		}

		this.injector.get(TimberService).error(timberMsg, logParameters);

		console.error(error);

		if (error.message && error.message.indexOf(navigationErrorPrefix) > -1) {
			this.displayErrorMessage(error, this.translate('Errors.Error_Navigate_Containter_Message'));
			return;
		}

		switch (error.status) {
			case 400: // Bad Request
				this.displayErrorMessage(error, this.translate('Errors.Error_400_Containter_Message'));
				break;
			case 401: // Unauthorised (not logged in)
				break;
			case 403: // Forbidden
				this.displayErrorMessage(error, this.translate('Errors.Error_403_Containter_Message'));
				break;
			case 404: // Content Not Found (page not found is dealt with above)
				this.displayErrorMessage(error, this.translate('Errors.Error_404_Containter_Message'));
				break;
			case 500: // Internal Server Error
				this.displayErrorMessage(error, this.translate('Errors.Error_500_Containter_Message'));
				break;
			case 504: // Timeout
				this.displayErrorMessage(error, this.translate('Errors.Error_504_Containter_Message'));
				break;
			case undefined: // Usually - client-side in-page error
				this.displayErrorMessage(error, this.translate('Errors.Error_Client_Containter_Message'));
				break;
			default:
				this.displayErrorMessage(error);
				break;
		}
	}

	public displayErrorMessage(error: any, containerTitle: string = null) {
		const isProd = this.appConfigService.appSettings.isProduction;

		const standardMessage = this.translate('Errors.StandardErrorMessage');
		const standardTitle = this.translate('Errors.StandardErrorTitle');

		const splitStack = error.stack ? error.stack.split('/n') : [standardTitle, standardMessage];

		let title = standardTitle;
		let message = standardMessage;
		if (!isProd) {
			title = containerTitle || splitStack[0];
			message = this.getErrorMessage(error);
		}
		this.injector.get(EupToastService).error(message, title, true, { enableHtml: true });
	}

	private getErrorMessage(error: any) {
		if (typeof error === 'string' || error instanceof String) {
			return error;
		}
		if (error.stack) {
			return error.stack;
		}
		if (error.error) {
			const myIteroError = error.error as IMyIteroException;
			return `Source: ${myIteroError.Source}.<br/> RouteData: ${myIteroError.RouteData}.<br/>
			 ${myIteroError.ExceptionMessage}.<br/> ${myIteroError.ExceptionStackTrace}`;
		}
	}

	private translate(key: string) {
		return this.translateService.instant(key);
	}
}
