import { Component, ViewChild, OnDestroy, OnInit, HostListener } from '@angular/core';
import { EupRoutesService } from '@core/eupRoutes.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ExocadMiniAppOptions } from './exocadMiniAppOptions';
import { BiLocationObject } from '@logging/interfaces/bi.enums';
import { BiFeatureWrapperService } from '@logging/bi-feature-wrapper.service';
import { TranslateService } from '@ngx-translate/core';
import { TimberService } from '@logging/timber.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { LocalStorageService } from '@core/localStorage.service';
import { SessionInfo } from 'app/services/authentication/auth.service';
import { Consts } from '@shared/consts';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { jwtDecode } from 'jwt-decode';
import { TokenRefreshService } from 'app/services/authentication/token-refresh.service';
import { EupHttpHandler } from '@core/eupHttpHandler.service';

const closeOnBlurAfterSec = 10;
const closeAfterSec = 999999; // Do not close the dialog if it's in focus

@Component({
	selector: 'exocad-miniAppModal',
	templateUrl: './exocadMiniAppModal.component.html',
	styleUrls: ['./exocadMiniAppModal.component.scss'],
})
export class ExocadMiniAppModal implements OnDestroy, OnInit {
	@ViewChild('exocadMiniAppModal', { static: true }) exocadMiniAppModal: ModalDirective;

	exocadInstallerUrl: string;
	orderId: number;
	formattedAppLongName: string;
	formattedAppShortName: string;
	unformattedAppShortName : string;

	private closeOnBlurTimeoutId:  ReturnType<typeof setTimeout>;
	private closeAfterTimeoutId:  ReturnType<typeof setTimeout>;

	constructor(
		private eupRoutesService: EupRoutesService,
		private translateService: TranslateService,
		private timberService: TimberService,
		private biFeatureWrapperService: BiFeatureWrapperService,
		private localStorageService: LocalStorageService,
		private globalSettings: GlobalSettingsService,
		private tokenRefreshService: TokenRefreshService,
		private http: EupHttpHandler) {}

	ngOnInit() {
		this.exocadMiniAppModal.config = {};
		this.exocadMiniAppModal.config.backdrop = false;
		this.exocadInstallerUrl = this.eupRoutesService.exocadMiniAppInstallerUrl;

		this.formattedAppLongName = `<b>${this.translateService.instant('Orders.MyDesignAppLongName')}</b>`;
		this.formattedAppShortName = `<b>${this.translateService.instant('Orders.MyDesignAppShortName')}</b>`;
		this.unformattedAppShortName = `${this.translateService.instant('Orders.MyDesignAppShortName')}`;
	}

	// Use 'blur' since Page Visibility API works only when switching tabs or collapsing the window, but not on Alt-Tab
	@HostListener('window:blur')
	onWindowBlur() {
		if (!this.closeAfterTimeoutId) {
			// The dialog is not visible
			return;
		}

		if (this.closeOnBlurTimeoutId) {
			// Already awaiting closing
			return;
		}

		this.closeOnBlurTimeoutId = this.closeAfter(closeOnBlurAfterSec);
	}

	ngOnDestroy() {
		this.clearTimeouts();
	}

	show(options: ExocadMiniAppOptions): void {
		this.clearTimeouts();

		this.openExoConnector(options)
		this.exocadMiniAppModal.show();
	}

	openExoConnector(options: ExocadMiniAppOptions): void {
		const sessionInfo = this.localStorageService.getItemOrEmpty<SessionInfo>(Consts.Storage.SessionInfo);
		let tokenExpiring = false;

		try {
			const decodedToken = jwtDecode(sessionInfo.accessToken);

			const tokenExpiringSkew = 10000 * 60; // 10 minutes
			// Consider token expiring soon if it expires in 15 minutes or less
			tokenExpiring = decodedToken.exp && Date.now() >= decodedToken.exp * 1000 - tokenExpiringSkew;
		}
		catch {}

		if (!tokenExpiring) {
			this.startExoConnector(options, sessionInfo);
		}
		else {
			// Make sure that the token is as fresh as possible
			firstValueFrom(this.tokenRefreshService.refreshToken(sessionInfo))
			.then(_ => {
				// Get the updated token
				const sessionInfo = this.localStorageService.getItemOrEmpty<SessionInfo>(Consts.Storage.SessionInfo);

				this.startExoConnector(options, sessionInfo);	
			})
			.catch(() => {
				// Do not show an error for 401 from token refresh
			});
		}
	}

	private async startExoConnector(options: ExocadMiniAppOptions, sessionInfo: SessionInfo) {
		const protocolPath = await this.getProtocolPath(options, sessionInfo);
		this.orderId = options.orderId;

		this.openProtocolLink(protocolPath);
	}

	public openProtocolLink(protocolPath: string): void {
		const link = document.createElement('a');
		link.setAttribute('href', protocolPath);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}

	onShown() {
		this.closeAfterTimeoutId = this.closeAfter(closeAfterSec);
	}

	onHidden() {
		this.clearTimeouts();
	}

	private closeAfter(waitSecBeforeClosing: number): ReturnType<typeof setTimeout> {
		this.clearTimeouts();

		return setTimeout(() => {
			this.exocadMiniAppModal.hide();

			this.clearTimeouts();
		}, waitSecBeforeClosing * 1000);
	}

	private clearTimeouts() {
		clearTimeout(this.closeAfterTimeoutId);
		clearTimeout(this.closeOnBlurTimeoutId);

		this.closeAfterTimeoutId = undefined;
		this.closeOnBlurTimeoutId = undefined;
	}
	
	onMyDesignAppDownloadInstallerClick() {
		const {userAction: userAction} = this.biFeatureWrapperService.LogDownloadInstallMyDesignAppClicked(BiLocationObject.Orders, this.orderId);
		this.timberService.userActionEvent(userAction);
	}
	
	private async getProtocolPath(options: ExocadMiniAppOptions, sessionInfo: SessionInfo): Promise<string> {
		const settings = this.globalSettings.get();		
		let protocolPath = `exoconnector://?rxId=${options.rxId}&orderId=${options.orderId}&sessionId=${sessionInfo.sessionId}&accessToken=${sessionInfo.accessToken}&selectedCompanyId=${settings.selectedCompanyId}&selectedDoctorId=${settings.selectedDoctorId}&selectedLanguage=${settings.selectedLanguage?.code}`;

		const parametersUrl = this.eupRoutesService.exocadMiniApp.getIntegrationParameters(settings.selectedCompanyId);
		const region = this.eupRoutesService.Region;

		protocolPath += `&parametersUrl=${encodeURIComponent(parametersUrl)}`;
		protocolPath += `&region=${encodeURIComponent(region)}`;

		return protocolPath;
	}
}

