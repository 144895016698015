import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForbbidenComponent } from './errors/forbidden.component';
import { PageNotFoundComponent } from './errors/pageNotFound.component';
import { InternalServerErrorComponent } from './errors/internalServerError.component';
import { BadRequestComponent } from './errors/badRequest.component';
import { PasswordPolicyGuard } from './core/passwordPolicy/passwordPolicyGuard.service';
import { RolesGuard } from './core/roles/rolesGuard.service';
import { FeatureToggle, RoleTypeEnum } from './shared/enums';
import { AuthGuard } from './core/auth.guard';
import { RootGuard } from './core/root.guard';
import { FeaturesToggleSettingsGuard } from './featuresToggleSettings/featuresToggleSettingsGuard.service';
import { CacheManagementGuard } from './cacheManagement/cacheManagementGuard.service';
import { ErrorsDemoGuard } from './errors/errorsDemo/errorsDemoGuard.service';
import { RegionGuard } from '@core/region/region.guard';
import { FeatureToggleGuard } from '@core/feature.toggle.gurad';

const routes: Routes = [
	{
		path: '',
		canActivateChild: [AuthGuard],
		children: [
			{
				path: '',
				canActivate: [RootGuard], // guard acts as redirection service here
				children: []
			},
			{
				path: 'doctors',
				loadChildren: () => import('./doctors/doctors.module').then(m => m.DoctorsModule),
				canActivate: [RegionGuard, PasswordPolicyGuard, RolesGuard],
				data: {
					expectedRoles: [RoleTypeEnum.Doctor]
				}
			},
			{
				path: 'messages',
				loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
				canActivate: [PasswordPolicyGuard, RolesGuard],
				data: { allowAnonymous: true }
			},
			{
				path: 'notifications',
				loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule)
			},
			{
				path: 'activityreport',
				loadChildren: () => import('./activityReport/activityReport.module').then(m => m.ActivityReportModule),
				canActivate: [PasswordPolicyGuard, RolesGuard],
				data: {
					expectedRoles: [
						RoleTypeEnum.Doctor,
						RoleTypeEnum.Personnel,
						RoleTypeEnum.Distributor,
						RoleTypeEnum.ManagementCompany
					]
				}
			},
			{
				path: 'rx',
				loadChildren: () => import('./rx/rx.module').then(m => m.RxModule),
				canActivate: [PasswordPolicyGuard, RolesGuard],
				data: {
					expectedRoles: [
						RoleTypeEnum.Doctor,
						RoleTypeEnum.Personnel,
						RoleTypeEnum.Distributor,
						RoleTypeEnum.ManagementCompany,
						RoleTypeEnum.Lab
					]
				}
			},
			{
				path: 'personnels',
				loadChildren: () => import('./personnels/personnels.module').then(m => m.PersonnelsModule),
				canActivate: [RegionGuard, PasswordPolicyGuard, RolesGuard],
				data: {
					expectedRoles: [RoleTypeEnum.Personnel]
				}
			},
			{
				path: 'features',
				loadChildren: () => import('./featuresToggleSettings/featuresToggleSettings.module').then(m => m.FeaturesToggleSettingsModule),
				canActivate: [PasswordPolicyGuard, RolesGuard],
				canLoad: [FeaturesToggleSettingsGuard],
				data: {
					expectedRoles: [
						RoleTypeEnum.Doctor,
						RoleTypeEnum.Personnel,
						RoleTypeEnum.Distributor,
						RoleTypeEnum.ManagementCompany,
						RoleTypeEnum.Lab
					]
				}
			},
			{
				path: 'cache',
				loadChildren: () => import('./cacheManagement/cacheManagement.module').then(m => m.CacheManagementModule),
				canActivate: [ PasswordPolicyGuard, RolesGuard ],
				canLoad: [ CacheManagementGuard ],
				data: {
					expectedRoles: [
						RoleTypeEnum.Doctor,
						RoleTypeEnum.Personnel,
						RoleTypeEnum.Distributor,
						RoleTypeEnum.ManagementCompany,
						RoleTypeEnum.Lab
					]
				}
			},
			{
				path: 'errorsdemo',
				loadChildren: () => import('./errors/errorsDemo/errorsDemo.module').then(m => m.ErrorsDemoModule),
				canActivate: [PasswordPolicyGuard, RolesGuard],
				canLoad: [ErrorsDemoGuard],
				data: {
					expectedRoles: [
						RoleTypeEnum.Doctor,
						RoleTypeEnum.Personnel,
						RoleTypeEnum.Distributor,
						RoleTypeEnum.ManagementCompany,
						RoleTypeEnum.Lab
					]
				}
			},
			{
				path: 'distributors',
				loadChildren: () => import('./distributors/distributors.module').then(m => m.DistributorsModule),
				canActivate: [RegionGuard, PasswordPolicyGuard, RolesGuard],
				data: {
					expectedRoles: [RoleTypeEnum.Distributor]
				}
			},
			{
				path: 'managementcompanies',
				loadChildren: () => import('./managementCompanies/managementCompanies.module').then(m => m.ManagementCompaniesModule),
				canActivate: [RegionGuard, PasswordPolicyGuard, RolesGuard],
				data: {
					expectedRoles: [RoleTypeEnum.ManagementCompany]
				}
			},
			{
				path: 'labs',
				loadChildren: () => import('./labs/labs.module').then(m => m.LabsModule),
				canActivate: [RegionGuard, PasswordPolicyGuard, RolesGuard],
				data: {
					expectedRoles: [RoleTypeEnum.Lab]
				}
			},
			{
				path: 'practice-management',
				loadChildren: () => import('./practiceManagement/doc-practice-management/doc-practice-management.module').then(m => m.DocPracticeManagementModule),
				canActivate: [RolesGuard],
				data: {
					expectedRoles: [
						RoleTypeEnum.Doctor,
						RoleTypeEnum.Personnel,
						RoleTypeEnum.Distributor,
						RoleTypeEnum.ManagementCompany
					]
				}
			},
			{
				path: 'analytics',
				loadChildren: () => import('./shared/analytics/analytics.module').then(m => m.AnalyticsModule)
			},
			{
				path: 'mfe-wrapper',
				loadChildren: () => import('./mfe-wrapper/mfe-wrapper.module').then(m => m.MfeWrapperModule),
				canActivate: [FeatureToggleGuard],
                data: {
                    featureToggles: [FeatureToggle.OpenAohsUI],
                }
			},
		]
	},
	{
		path: 'iosimauthresponse',
		loadChildren: () => import('./shared/iosim/iosimAuthResponse/iosimAuthResponse.module').then(m => m.IosimAuthResponseModule)
	},
	{
		path: 'passwordpolicy',
		loadChildren: () => import('./passwordPolicy/passwordPolicy.module').then(m => m.PasswordPolicyModule)
	},
	{
		path: 'recoverpassword',
		loadChildren: () => import('./recoverPassword/recoverPassword.module').then(m => m.RecoverPasswordModule)
	},
	{
		path: 'thirdpartylogin',
		loadChildren: () => import('./thirdPartyLogin/thirdPartyLogin.module').then(m => m.ThirdPartyLoginModule)
	},
	{
		path: 'logonas',
		loadChildren: () => import('./logonAs/logonAs.module').then(m => m.LogonAsModule),
		canActivate: [RolesGuard],
		data: {
			expectedRoles: [RoleTypeEnum.Internal]
		}
	},
	{ path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), data: { isResponsive: true } },
	{ path: 'auth-provider-callback', loadChildren: () => import('./authProvider/authProvider.module').then(m => m.AuthProviderModule), data: { isResponsive: true } },
	{ path: 'forbidden', component: ForbbidenComponent, pathMatch: 'full', data: { title: '403' } },
	{ path: 'pagenotfound', component: PageNotFoundComponent, pathMatch: 'full', data: { title: '404' } },
	{ path: 'internalservererror', component: InternalServerErrorComponent, pathMatch: 'full', data: { title: '500' } },
	{ path: 'badrequest', component: BadRequestComponent, pathMatch: 'full', data: { title: '400' } },
	{ path: '**', component: PageNotFoundComponent, data: { title: '404' } }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutes { }
