import { Injectable } from '@angular/core';
import { EupHttpHandler } from './eupHttpHandler.service';
import { LocalStorageService } from './localStorage.service';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { LocaleData } from '@shared/generalInterfaces';
import { Consts } from '@shared/consts';
import { PatientType, MessengerType } from '@shared/enums';

@Injectable()
export class EupRoutesService {
	private static apiPrefix: string = 'mvc/';
	private static mvcUrl: string;
	private static MATserverUrl: string;
	private static serverUrl: string;
	private static cdnPath: string;
	private static rxUIUrl: string;
	private storage: Storage;

	public mvcUrl: any;
	public serverUrl: any;
	public cdnPath: any;
	public MATserverUrl: any;
	public notificationAgentUrl: string;
	public iTeroWebAuthApiUrl: string;
	public iTeroSiteUrl: any;
	public rxUIUrl: any;
	public idsPairing: any;
	public accountManagementPublicApiUrl: string;
	public exocadMiniAppInstallerUrl: string;
	public clientDiscoveryUrl: string;
	public assetManagerUrl: string;

	public downloads: any;
	public home: any;
	public login: any;
	public orders: any;
	public lab: any;
	public rx: any;
	public cache: any;
	public rxForm: any;
	public messages: any;
	public patients: any;
	public accountManagement: any;
	public iOSimPlus: any;
	public patientOrders: any;
	public logonAs: any;
	public contact: any;
	public userSettings: any;
	public featuresToggleSettings: any;
	public recoverPassword: any;
	public companySettings: any;
	public activityReport: any;
	public thirdPartyLogin: any;
	public shared: any;
	public exocadMiniApp: any;
	public viewer: any;
	public orderInformation: any;
	public orderConfiguration: any;
	public accountPairing: any;
	public AlignUrl: string;
	public PrivacyNCookiesUrl: string;
	public TermsUrl: string;
	public Region: string;
	public MIDCUrlForRegionRedirect: string;
	public TermsUrlObs = new BehaviorSubject({});
	private initHandle: (force: boolean) => Observable<any>;
	public drawingToolUrl: string;
	public labSettings: any;
	public hostedApps: any;
	public labAgreement: any;

	constructor(
		private http: EupHttpHandler,
		private localStorageService: LocalStorageService,
		private appConfigService: AppConfigService
	) {
		this.storage = localStorageService.storage;
		if (this.routeCanBeUsed()) {
			const routingData = JSON.parse(this.storage[Consts.Storage.Routes]) as RoutingData;
			this.initialize(routingData);
		}

		this.initHandle = (force) => {
			return from(this.getRoutes(force).pipe(
				flatMap((res: any) => this.initStorage(res, force))
			));
		};
	}

	getRoutes(forceDataRetrieve: boolean = false): Observable<RoutingData> {
		if (this.routeCanBeUsed(forceDataRetrieve) && !this.isRoutesVersionChanged()) {
			return new Observable<RoutingData>((observer) => {
				observer.next(JSON.parse(this.storage[Consts.Storage.Routes]) as RoutingData);
			});
		}

		let apiEndpoint = this.storage[Consts.Storage.RegionalApiEndpoint]
			? this.storage[Consts.Storage.RegionalApiEndpoint]
			: this.appConfigService.appSettings.apiEndpoint;
		if (apiEndpoint?.endsWith('/')) {
			apiEndpoint = apiEndpoint.slice(0, -1);
		}
		return this.http.get(`${apiEndpoint}/${EupRoutesService.apiPrefix}settings/GetRoutingData`);
	}

	getLocalizationSettings(): Observable<LocaleData> {
		return this.http.get(
			`${this.appConfigService.appSettings.apiEndpoint}/${EupRoutesService.apiPrefix}settings/getLocalizationSettings`
		);
	}

	getFeaturesToggleSettingsEndpoint(): string {
		return `${this.appConfigService.appSettings.apiEndpoint}/${EupRoutesService.apiPrefix}featuresToggleSettings/getFeaturesToggleSettings`;
	}

	initStorage(res: RoutingData, forceDataRetrieve: boolean = false): Observable<Object> {
		if (this.routeCanBeUsed(forceDataRetrieve) && !this.isRoutesVersionChanged()) {
			return new Observable((observer) => {
				this.initialize(res);
				observer.next({});
			});
		}

		return new Observable((observer) => {
			this.storage[Consts.Storage.Routes] = JSON.stringify(res);
			this.storage[Consts.Storage.RegionalApiEndpoint] = this.storage[Consts.Storage.RegionalApiEndpoint]
				? this.storage[Consts.Storage.RegionalApiEndpoint]
				: res.serverUrl;
			this.storage[Consts.Storage.ServerVersion] = res.version;
			this.initialize(res);
			observer.next({});
		});
	}

	getLoginData(): string {
		if (!this.login) {
			throw 'routing data not initialised!';
		}

		return this.login.loginData;
	}

	doAfterInit<T>(observable: () => Observable<T>, force: boolean = false): Observable<T> {
		return this.initHandle(force)
			.pipe(
				flatMap(observable)
			);
	}

	initialize(routingData: RoutingData) {
		if (!routingData) {
			return; // no data or init was already scheduled before
		}

		this.notificationAgentUrl = routingData.notificationAgentUrl;
		this.iTeroWebAuthApiUrl = routingData.iTeroWebAuthApiUrl;
		this.hostedApps = class HostedApps{
			static exocad3dViewerEndpoint = routingData.Exocad3dViewerEndpoint;
			static analyticsUIEndpoint = routingData.AnalyticsUIEndpoint
		}
		EupRoutesService.serverUrl = this.serverUrl = routingData.serverUrl;
		EupRoutesService.cdnPath = this.cdnPath = routingData.cdnPath;
		EupRoutesService.MATserverUrl = this.MATserverUrl = routingData.MATserverUrl;
		EupRoutesService.mvcUrl = this.mvcUrl = this.serverUrl + EupRoutesService.apiPrefix;
		EupRoutesService.rxUIUrl = this.rxUIUrl = routingData.rxUIUrl;
		this.AlignUrl = routingData.alignUrl;
		this.PrivacyNCookiesUrl = routingData.privacyAndCookiesUrl;
		this.TermsUrl = routingData.termsUrl;
		this.TermsUrlObs.next(this.TermsUrl);
		this.drawingToolUrl = routingData.drawingToolUrl;
		this.accountManagementPublicApiUrl = routingData.accountManagementPublicApiUrl;
		this.exocadMiniAppInstallerUrl = routingData.exocadMiniAppInstallerUrl;
		this.clientDiscoveryUrl = routingData.clientDiscoveryUrl;		
		this.assetManagerUrl = routingData.assetManagerUrl;

		this.iTeroSiteUrl = routingData.iTeroSiteUrl;
		this.Region = routingData.region;
		this.MIDCUrlForRegionRedirect = routingData.MIDCUrlForRegionRedirect;
		this.downloads = class DownloadRoute {
			static orthoCadSetupDownloadUrl = routingData.orthoCadSetupDownloadUrl;
			static orthoCadSetupDownloadUrl_acs = routingData.orthoCadSetupDownloadUrl_acs;
			static iTeroSetupDownloadUrl = routingData.iTeroSetupDownloadUrl;
			static iTeroSetupDownloadUrl_acs = routingData.iTeroSetupDownloadUrl_acs;
			static iOSimDownloadUrl = routingData.iOSimDownloadUrl;
			static iOSimDownloadUrl_acs = routingData.iOSimDownloadUrl_acs;
			static compatibilityTester = routingData.compatibilityTester;
			static RTHFirstTimeInstaller = routingData.RTHFirstTimeInstaller;
			static EvxFirstTimeInstaller = routingData.EvxFirstTimeInstaller;
		};

		this.home = class HomeRoute {
			static url = EupRoutesService.mvcUrl + 'home/';
			static logout = HomeRoute.url + 'logout';
		};

		this.login = class LoginRoute {
			static url = EupRoutesService.mvcUrl + 'login/';
			static loginData = LoginRoute.url + 'getLoginInfo';
			static loginToMidc = LoginRoute.url + 'loginToMidc';
			static browserLanguageCode = LoginRoute.url + 'browserLanguageCode';
			static getUserData = (username: string) => `${LoginRoute.url}getUserData?userName=${username}`;
			static loginInternalToMidc =`${LoginRoute.url}LoginInternalToMidc`;
		};

		this.thirdPartyLogin = class ThirdPartyLoginRoute {
			static url = EupRoutesService.mvcUrl + 'thirdPartyLogin/';
			static authorize = ThirdPartyLoginRoute.url + 'authorize/';
			static setCompany = ThirdPartyLoginRoute.url + 'setCompany/';
		};

		this.orders = class OrderRoute {
			static url = EupRoutesService.mvcUrl + 'orders/';
			static filesUrl = EupRoutesService.mvcUrl + 'orderfiles/';
			static undeliveredUrl = OrderRoute.url + 'undelivered';
			static deliveredUrl = OrderRoute.url + 'delivered';
			static deliveredFrontlineOrdersUrl = OrderRoute.url + 'deliveredFrontlineOrders';
			static getOrderByIdUrl = OrderRoute.url + 'getOrderById';
			static getBaseOrderDataUrl = OrderRoute.url + 'getBaseOrderData';
			static sendPendingAttachmentsOrder = OrderRoute.url + 'SendPendingAttachmentsOrder';
			static getOrderCode = OrderRoute.url + 'getOrderCode';
			static validateOrderUrl = OrderRoute.url + 'validateOrder';
			static deleteRxUrl = OrderRoute.url + 'deleteRx';
			static getOrderStatusesUrl = OrderRoute.url + 'GetOrderStatuses';
			static changeCaseTypeOptionsUrl = OrderRoute.url + 'GetChangeCaseTypeOptions';
			static changeCaseTypeForOrderUrl = OrderRoute.url + 'ChangeCaseTypeForOrder';
			static getIdeFile = OrderRoute.filesUrl + 'getIdeFile';
			static getIdeFileByWorkOrder = OrderRoute.filesUrl + 'GetIdeFileByWorkOrder';
			static getScreenshotDownloadLink = OrderRoute.filesUrl + 'DownloadCaptureFile';
			static isEvxFileExists = OrderRoute.filesUrl + 'IsEvxFileExists';
			static getFileStorage = OrderRoute.filesUrl + 'GetFileStorage';
			static updatePrintedOrdersUrl = OrderRoute.url + 'updatePrintedOrders';
			static uploadCapture = OrderRoute.filesUrl + 'UploadCaptureFile';
			static uploadIOSimCapture = OrderRoute.filesUrl + 'UploadIOSimPlusCaptureFile';
			static setReadyForDownloadAsDownloadedForLab = OrderRoute.url + 'setReadyForDownloadAsDownloadedForLab';
			static downloadGalleryImages = OrderRoute.filesUrl + 'DownloadGalleryImages';

			// Called by lab users from dashboard to check if files already exists
			static getExportInfoBulkByWorkOrder = OrderRoute.filesUrl + 'GetExportInfoBulkByWorkOrder';

			// Called by lab user from rx / viewer screen to check if files already exists
			static getOrthodonticsExportInfoByWorkOrder = OrderRoute.filesUrl + 'getOrthodonticsExportInfoByWorkOrder';
			static getRestorativeExportInfoByWorkOrder = OrderRoute.filesUrl + 'getRestorativeExportInfoByWorkOrder';

			// Called by doctors from rx / viewer screen to check if files already exists
			static getOrthodonticsExportInfo = OrderRoute.filesUrl + 'getOrthodonticsExportInfo';
			static getRestorativeExportInfo = OrderRoute.filesUrl + 'getRestorativeExportInfo';

			// Use for get export progress
			static getExportStatusBulk = OrderRoute.filesUrl + 'GetExportStatusBulk';
		};

		this.lab = class LabRoute {
			static url = EupRoutesService.mvcUrl + 'lab/';
			static getOrdersUrl = LabRoute.url + 'getOrders';
			static getFrontlineOrdersUrl = LabRoute.url + 'getFrontlineOrders';
			static getOrdersTotalUrl = LabRoute.url + 'getOrdersTotal';
			static getFrontlineOrdersTotalUrl = LabRoute.url + 'GetFrontlineOrdersTotal';
			static getTotalOrdersAmountByState = LabRoute.url + 'getTotalOrdersAmountByState';
			static getTotalFrontlineOrdersAmountByState = LabRoute.url + 'GetTotalFrontlineOrdersAmountByState';
			static logout = LabRoute.url + 'logout';
			static proceedToMilling = LabRoute.url + 'proceedToMilling';
			static proceedToModeling = LabRoute.url + 'proceedToModeling';
			static backToScanning = LabRoute.url + 'backToScanning';
			static getRescanInfo = LabRoute.url + 'GetRescanInfo';
			static getOrderByIdUrl = LabRoute.url + 'getOrderById';
			static buildITeroProtocolLink = LabRoute.url + 'BuildITeroProtocolLink';
			static processSendToLab = LabRoute.url + 'ProcessSendToLab';
			static undoReturnCase = LabRoute.url + 'UndoReturnCase';
			static getStates = LabRoute.url + 'GetStates';
			static addState = LabRoute.url + 'AddState';
		};

		this.rx = class RxRoute {
			static endpointForRx = EupRoutesService.mvcUrl + 'rx';
			static endpointForGlidewellRx = EupRoutesService.mvcUrl + 'ThirdPartyRx';
			static rxUrl = EupRoutesService.rxUIUrl + '/UnSecured/RxAppBeta/RxEditor.html';
			static webRxUrl = EupRoutesService.rxUIUrl + '/UnSecured/RxAppBeta/WebRx.html';
			static printRxUrl = EupRoutesService.rxUIUrl + '/UnSecured/RxAppBeta/printrx.html';
			static onlineOrthoViewerUrl = routingData.onlineOrthoViewerUrl;
			static onlineRestorativeViewerUrl = routingData.onlineRestorativeViewerUrl;
			static onlineOrthoPoiUiReaderStudyUrl = routingData.onlineOrthoPoiUiReaderStudyUrl;
			static onlineRestorativePoiUiReaderStudyUrl = routingData.onlineRestorativePoiUiReaderStudyUrl;
			static getAvailableCaseTypesForRxUrl = RxRoute.endpointForRx + '/GetAvailableCaseTypes';
			static configurationUrl = RxRoute.endpointForRx + '/Configuration';
			static saveUrl = RxRoute.endpointForRx + '/Save';
			static getUserSettingsUrl = RxRoute.endpointForRx + '/GetUserSettings';
			static rxContactConfigUrl = RxRoute.endpointForRx + '/RxContactConfig';
			static getRxByIdUrl = RxRoute.endpointForRx + '/GetRxById';
			static getRxByOrderIdUrl = RxRoute.endpointForRx + '/GetRxByOrderId';
			static getPatientByUIDUrl = (patientGuid: string, doctorId: number, companyId: number) => `${RxRoute.endpointForRx}/GetPatientByUID?id=${patientGuid}&doctorId=${doctorId}&companyId=${companyId}`;
			static checkPatientExistByUrl = RxRoute.endpointForRx + '/CheckPatientExistBy';
			static validateClinicRxUrl = RxRoute.endpointForRx + '/ValidateClinicRx';
			static getBaseRxUrl = RxRoute.endpointForRx + '/GetBaseRx';
		};

		this.cache = class CacheRoute {
			static url = EupRoutesService.mvcUrl + 'redisManagement/';
			static getDeleteCacheUrl = (key: string) => `${CacheRoute.url}deleteCache?key=${key}`;
		};

		this.rxForm = class RxFormRoute {
			static endpointForRxForm = EupRoutesService.mvcUrl + 'rxForm';
			static saveOrderRxFormUrl = RxFormRoute.endpointForRxForm + '/SaveOrderRxForm';
			static activateReferral = RxFormRoute.endpointForRxForm + '/ActivateReferral';
			static LoadRxByReferralCodeUrl = RxFormRoute.endpointForRxForm + '/LoadRxByReferralCode';
			static getDetailsByReferralCodeUrl = (referralCode: number, companyId: number) => RxFormRoute.endpointForRxForm + `/GetDetailsByReferralCode?ReferralCode=${referralCode}&CompanyId=${companyId}`;
		};

		this.featuresToggleSettings = class FeaturesToggleSettingsRoute {
			static url = EupRoutesService.mvcUrl + 'featuresToggleSettings/';
			static getFeaturesToggleSettings = FeaturesToggleSettingsRoute.url + 'getFeaturesToggleSettings/';
			static updateFeaturesToggleSettings = FeaturesToggleSettingsRoute.url + 'updateFeaturesToggleSettings/';
		};

		this.recoverPassword = class RecoverPasswordRoute {
			static url = EupRoutesService.mvcUrl + 'settings/';
			static getUserAuthenticationDetails = (userName: string) =>
				RecoverPasswordRoute.url + `getUserAuthenticationDetails?userName=${userName}`;
			static resetUserPassword = RecoverPasswordRoute.url + 'resetUserPassword';
		};

		this.messages = class MessageRoute {
			static url = EupRoutesService.mvcUrl + 'messages/';
			static getMessagesListUrl = (companyId, scannerVersion: string, scannerId: string) =>
				MessageRoute.url + `GetMessagesList?companyId=${companyId}&scannerVersion=${scannerVersion}&scannerId=${scannerId}`;
			static newMessagesCountUrl = (companyId, scannerVersion: string, scannerId: string) =>
				MessageRoute.url + `GetNewMessagesCount?companyId=${companyId}&scannerVersion=${scannerVersion}&scannerId=${scannerId}`;
			static markAsReadUrl = MessageRoute.url + 'MarkAsRead';
			static markAsUnreadUrl = MessageRoute.url + 'MarkAsUnread';
		};

		this.patients = class PatientRoute {
			static url = EupRoutesService.mvcUrl + 'patients/';
			static searchUrl = PatientRoute.url + 'search';

			static getPatientsUrl = (patientTypes: PatientType[], companyId: number, forceCacheUpdate: boolean = false) => {
				if (!patientTypes || !Array.isArray(patientTypes)) {
					return '';
				}

				const patientTypeQuery = patientTypes.reduce((query, type) => {
					return `${query}patientTypes=${type}&`;
				}, '');
				return `${PatientRoute.url}?${patientTypeQuery}companyId=${companyId}&forceCacheUpdate=${forceCacheUpdate}`;
			}
		};

		this.patientOrders = class PatientOrdersRoute {
			static url = EupRoutesService.mvcUrl + 'patientsOrders/';
			static ordersUrl = PatientOrdersRoute.url + 'orders';
			static checkPatientDoesntExistAndUpdateUrl = PatientOrdersRoute.url + 'checkPatientDoesntExistAndUpdate';
		};

		this.logonAs = class LogonAsRoute {
			static url = EupRoutesService.mvcUrl + 'logonAs/';
			static getContacts = LogonAsRoute.url + 'getContacts';
			static getContactsById = LogonAsRoute.url + 'getContactsById';
			static getDoctorsByCompanyId = LogonAsRoute.url + 'getDoctorsByCompanyId';
		};

		this.contact = class ContactRoute {
			static url = EupRoutesService.mvcUrl + 'contact/';
			static isContactLinkedToChineseCompany = ContactRoute.url + 'isContactLinkedToChineseCompany';
		};

		this.userSettings = class UserSettingsRoute {
			static url = EupRoutesService.mvcUrl + 'settings/';
			static getUrl = UserSettingsRoute.url + 'getUserSettings';
			static setUrl = UserSettingsRoute.url + 'setUserSettings';
			static localizationSettingsByRoleTypeUrl = UserSettingsRoute.url + 'getLocalizationSettingsByRoleType';
			static setCredentials = UserSettingsRoute.url + 'setSecurityInfo';
			static setDoctorPatientConsent = UserSettingsRoute.url + 'setDoctorPatientConsent';
			static getVisibleUserAuthenticationDetails = UserSettingsRoute.url + 'GetVisibleUserAuthenticationDetails?userName=';
			static getAllSecurityQuestions = UserSettingsRoute.url + 'getAllSecurityQuestions';
			static matPasswordPolicyPageUrl = EupRoutesService.MATserverUrl + 'UnSecured/PasswordPolicyTouch.aspx?mode=EUP&Language={0}';
			static getCompanyRegionUrl = UserSettingsRoute.url + 'getCompanyRegion';
			static getExportGeneralSettingsUrl = UserSettingsRoute.url + 'getExportGeneralSettings';
			static setCompanySettingsUrl = UserSettingsRoute.url + 'setLabCompanySettings';
			static getCompanySettingsUrl = UserSettingsRoute.url + 'getLabCompanySettings';
			static areScannerSoftwareOptionsAvailableUrl = UserSettingsRoute.url + 'areScannerSoftwareOptionsAvailable';
			static getCompanySoftwareOptionIds = UserSettingsRoute.url + 'getCompanySoftwareOptionIds?companyId=';
			static getRegulatoryConfigurationUrl = UserSettingsRoute.url + 'getRegulatoryConfiguration';
			static setDoctor = (companyId: number, doctorId: number) =>
				`${UserSettingsRoute.url}setDoctor?companyId=${companyId}&doctorId=${doctorId}`;
			static getIsRxAppEnableUrl = (contactId: number, companyId: number) =>
				`${UserSettingsRoute.url}isRxAppEnable?clientId=${contactId}&companyId=${companyId}`;
		};

		this.activityReport = class ActivityReportRoute {
			static url = EupRoutesService.mvcUrl + 'activityReport/';
			static getUrl = ActivityReportRoute.url + 'getActivities';
			static getAllActivities = ActivityReportRoute.url + 'getAllActivities';
		};

		this.shared = class UserSettingsRoute {
			static url = EupRoutesService.mvcUrl + 'shared/';
			static getLanguages = UserSettingsRoute.url + 'getLanguages';
		};

		this.exocadMiniApp = class ExocadMiniAppRoute {
			static url = EupRoutesService.mvcUrl + 'ExocadMiniApp/';
			static getIntegrationParameters = (companyId : number) => ExocadMiniAppRoute.url + `IntegrationParameters?companyId=${companyId}`;
		};

		this.viewer = class ViewerRoute {
			static url = EupRoutesService.mvcUrl + 'viewer/';
			static logViewerLoadingTime = ViewerRoute.url + 'logViewerLoadingTime?milliseconds=';
			static canShowExport = ViewerRoute.url + 'canShowExport';
		};

		this.orderInformation = class ViewerRoute {
			static url = EupRoutesService.mvcUrl + 'orderInformationApi/';
			static configuration = ViewerRoute.url + 'OrderInformationConfiguration?companyId=';
			static canOrderPhysicalModel = `${ViewerRoute.url}CanOrderPhysicalModel?companyId=`;
		};

		this.orderConfiguration = class ViewerRoute {
			static getUrl = (companyId: string) => `${EupRoutesService.serverUrl}rx/Configuration/${companyId}?isLabOrTechnician=false`;
		};

		this.accountManagement = class AccountManagementRoute {
			static getPracticeUsersUrl = (companyId: string) =>
				`${EupRoutesService.serverUrl}AccountManagement/companies/${companyId}/contacts`;
			static updatePracticeUserUrl = (companyId: string) =>
				`${EupRoutesService.serverUrl}AccountManagement/companies/${companyId}/contacts`;
			static setPrimaryContactUrl = () => `${EupRoutesService.serverUrl}AccountManagement/SetPrimaryContact`;
			static setCompanyLogoUrl = () => `${EupRoutesService.serverUrl}/${EupRoutesService.apiPrefix}AccountManagement/SetCompanyLogo`;
			static deleteContactUrl = (companyId: string, contactId: string) =>
				`${EupRoutesService.serverUrl}AccountManagement/companies/${companyId}/contacts/${contactId}`;
			static getCompanyInfoUrl = (companyId: number) =>
				`${EupRoutesService.mvcUrl}accountManagement/GetCompanyInfo?companyId=${companyId}`;
			static url = EupRoutesService.mvcUrl + 'accountManagement/';
			static deleteContactAccountRelationUrl = (id: number, bpId: number) => `${AccountManagementRoute.url}deleteContactAccountRelation?id=${id}&bpId=${bpId}`;
			static getContactAccountRelations = `${AccountManagementRoute.url}getContactAccountRelations`;
			static updateContactAccountRelationUrl = `${AccountManagementRoute.url}updateContactAccountRelation`;
			static invitationsListUrl = `${AccountManagementRoute.url}getInvitations`;
			static saveInvitationUrl = `${AccountManagementRoute.url}saveInvitation`;
			static deleteInvitationUrl = (id: string, companyId: number) => `${AccountManagementRoute.url}deleteInvitation?id=${id}&businessPartnerId=${companyId}`;
			static getCountriesStates = () => `${AccountManagementRoute.url}GetCountriesStates`;
			static isInvitationEmailExists = (companyId: number, email: string) => `${AccountManagementRoute.url}IsInvitationEmailExist?companyId=${companyId}&email=${email}`;
			static isInvitationPhoneExists = (companyId: number, phone: string) => `${AccountManagementRoute.url}IsInvitationPhoneExist?companyId=${companyId}&phone=${phone}`;
			static contactHasRelations = (email: string) => `${AccountManagementRoute.url}contactHasRelations?email=${email}`;
			static isContactExistsInIds = (salesforceContactId: number) => `${AccountManagementRoute.url}IsContactExistsInIds?salesforceContactId=${salesforceContactId}`;
		};

		this.iOSimPlus = class IOSimPlusRoute {
			static getIOSimPlusLink = (orderCode: string, screen: string) =>
				`${EupRoutesService.mvcUrl}IOSimPlus/GetLink?orderCode=${orderCode}&utmSource=${screen}`
			static startSimulation = (orderId: string, screen: string) =>
				`${EupRoutesService.mvcUrl}IOSimPlus/StartSimulation?orderId=${orderId}&utmSource=${screen}`
			static getSimulationStatus = (orderCode: string, companyId: number) =>
				`${EupRoutesService.mvcUrl}IOSimPlus/SimulationStatus?orderCode=${orderCode}&companyId=${companyId}`
			static getExpectedDuration = (orderId: string, companyId: number) =>
				`${EupRoutesService.mvcUrl}IOSimPlus/GetExpectedDuration?orderId=${orderId}&companyId=${companyId}`
		};

		this.accountPairing = class PairingAccountRoute {
			static getPairingStatus = (messengerType: MessengerType, doctorId: number, companyId: number) =>
				`${EupRoutesService.mvcUrl}AccountPairing/GetPairingStatus?messengerType=${messengerType}&doctorId=${doctorId}&companyId=${companyId}`;
			static unpairAccount = () => `${EupRoutesService.mvcUrl}AccountPairing/UnpairAccount`;
		};

		this.idsPairing = class IdsPairing {
			static getIdsPairingData = () => `${EupRoutesService.mvcUrl}idsPairing/getIdsPairingData`;
			static getIdsLoginUrl = (langCode, companyId) => {
				const mvc = `${EupRoutesService.mvcUrl}idsPairing/getIdsLoginUrl`;
				const args = `?successEndpoint=${window.location.origin}/iosimauthresponse?isSuccess=true&failEndpoint=${window.location.origin}/iosimauthresponse?isSuccess=false&languageCode=${langCode}&companyId=${companyId}`;
				return `${mvc}${args}`;
			};
			static getIsDoctorPaired = () => `${EupRoutesService.mvcUrl}idsPairing/isDoctorPaired`;
			static getOAuthToken = (companyId) => `${EupRoutesService.mvcUrl}idsPairing/getOAuthToken?companyId=${companyId}`;
			static getIOSimLinkWithOAuth = (companyId, orderId) =>
				`${EupRoutesService.mvcUrl}orders/GetIOSimLinkWithOAuth?companyId=${companyId}&orderId=${orderId}`;
		};

		this.labSettings = class LabSettingsRoute {
			static url = EupRoutesService.mvcUrl + 'labSettings';
			static getThirdPartyIntegrationsUrl = (companyId: number) =>
				`${(this.url)}/getThirdPartyIntegrations?companyId=${companyId}`;
			static getCompanyConnectorsConfigurationUrl = (companyId: number, langCode: string) =>
			`${(this.url)}/getCompanyConnectorsConfiguration?companyId=${companyId}&langCode=${langCode}`;
			static setCompanyConnectorsConfigurationUrl = (companyId: number) =>
				`${(this.url)}/setCompanyConnectorsPreferences?companyId=${companyId}`
		};

		this.labAgreement = class LabAgreementRoute {
			static url = EupRoutesService.mvcUrl + 'labAgreement';

			static Confirm = (companyId: number, language: string, countryCode: string) =>
				`${(this.url)}/Confirm?companyId=${companyId}&language=${language}&countryCode=${countryCode}`
			static SaveOpeningTime = (companyId: number) =>
				`${(this.url)}/SaveOpeningTime?companyId=${companyId}`

			static getConfirmedForCompany = (companyId: number) =>
				`${(this.url)}/GetConfirmedForCompany?companyId=${companyId}`
		};
	}

	private routeCanBeUsed(forceDataRetrieve: boolean = false): boolean {
		const routes = this.storage[Consts.Storage.Routes];
		const currentRegion = this.storage[Consts.Storage.RegionalApiEndpoint];
		const routesObj = routes ? (JSON.parse(routes) as RoutingData) : undefined;
		const regionChanged = routesObj && currentRegion && routesObj.serverUrl && currentRegion !== routesObj.serverUrl;

		const res = !!routes && this.isValidJson(routes) && !forceDataRetrieve && !regionChanged;

		return res;
	}

	private isRoutesVersionChanged(): boolean {
		const routes = this.storage[Consts.Storage.Routes];
		const currentServerVersion = this.storage[Consts.Storage.ServerVersion];

		const routesObj = routes ? (JSON.parse(routes) as RoutingData) : undefined;
		const versionChanged = !currentServerVersion || !routesObj || routesObj.version !== currentServerVersion;

		return versionChanged;
	}

	private isValidJson(jsonText: string): boolean {
		try {
			JSON.parse(jsonText);
		} catch (e) {
			return false;
		}
		return true;
	}
}

export class RoutingData {
	serverUrl: string;
	cdnPath: string;
	MATserverUrl: string;
	iTeroSiteUrl: string;
	orthoCadSetupDownloadUrl: string;
	iTeroSetupDownloadUrl: string;
	iOSimDownloadUrl: string;
	compatibilityTester: string;
	RTHFirstTimeInstaller: string;
	EvxFirstTimeInstaller: string;
	onlineOrthoViewerUrl: string;
	onlineRestorativeViewerUrl: string;
	onlineOrthoPoiUiReaderStudyUrl:string;
	onlineRestorativePoiUiReaderStudyUrl:string;
	rxUIUrl: string;
	alignUrl: string;
	privacyAndCookiesUrl: string;
	termsUrl: string;
	orthoCadSetupDownloadUrl_acs: string;
	iTeroSetupDownloadUrl_acs: string;
	iOSimDownloadUrl_acs: string;
	drawingToolUrl: string;
	notificationAgentUrl: string;
	version: string;
	region: string;
	MIDCUrlForRegionRedirect: string;
	iTeroWebAuthApiUrl: string;
	Exocad3dViewerEndpoint: string;
	AnalyticsUIEndpoint: string;
	accountManagementPublicApiUrl: string;
	exocadMiniAppInstallerUrl: string;
	clientDiscoveryUrl: string;	
	assetManagerUrl: string;
}
